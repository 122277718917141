import { Button, LoadingOverlay, Modal, Radio } from '@mantine/core';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getAllTags,
  updateDatasetItems,
} from '../../../../../../action/dataset';

const DatasetItemsUntag = forwardRef((props, ref) => {
  const [totalItems, setTotalItems] = useState(0);
  const [opened, setOpened] = useState(false);
  const dispatch = useDispatch();
  const { datasetId } = useParams();
  const [tagOperation, setTagOperation] = useState('allTags');
  const { data: tags } = useSelector((state) => state.dataset.tags);
  const [selectedTag, setSelectedTag] = useState(undefined);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [apiCallInProgress, setApiCallInProgress] = useState(false);

  const handleUntagItems = async () => {
    if (showConfirmation === false) {
      setShowConfirmation(true);
      return;
    } else {
      setApiCallInProgress(true);
      let action;
      if (tagOperation === 'allTags') {
        action = await dispatch(
          updateDatasetItems({
            datasetId: datasetId,
            action: 'remove_all_tags',
          })
        );
      } else {
        action = await dispatch(
          updateDatasetItems({
            datasetId: datasetId,
            action: 'remove_tag',
            tagId: selectedTag.id,
          })
        );
      }

      if (
        updateDatasetItems.fulfilled.match(action) ||
        updateDatasetItems.rejected.match(action)
      ) {
        setApiCallInProgress(true);
        dispatch(getAllTags({ datasetId }));
        props.fetchDataset();
        setOpened(false);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    openUntagModal(_totalItems) {
      setSelectedTag(undefined);
      setShowConfirmation(false);
      setTagOperation('allTags');
      setTotalItems(_totalItems);
      setApiCallInProgress(false);
      setOpened(true);
    },
  }));

  return (
    <Modal.Root
      opened={opened}
      onClose={() => setOpened(false)}
      centered
      size="lg">
      <Modal.Overlay />

      <Modal.Content>
        <Modal.Header>
          <h1 className="text-xl font-medium">
            {showConfirmation
              ? `Untag ${
                  tagOperation === 'selectedTagsOnly'
                    ? selectedTag.task_count
                    : totalItems
                } items?`
              : 'UnTag'}
          </h1>
        </Modal.Header>
        <Modal.Body>
          <div>
            {showConfirmation && (
              <div className="text-xl my-6 text-center">
                All will get untagged. Are you sure you want to proceed?
              </div>
            )}

            {!showConfirmation && (
              <div>
                <Radio.Group
                  className="flex flex-row items-center justify-between px-3 mb-10 mt-2"
                  value={tagOperation}
                  onChange={setTagOperation}>
                  <Radio
                    label={`Untag (${totalItems} items)`}
                    value="allTags"
                    color="red"
                  />
                  <Radio
                    label="Select a tag to Untag"
                    value="selectedTagsOnly"
                    color="red"
                  />
                </Radio.Group>

                {tags && tagOperation === 'selectedTagsOnly' && (
                  <div className="flex flex-col gap-2 p-2 border border-gray-30 rounded-md max-h-[200px] overflow-y-auto">
                    {tags.map((tag) => (
                      <div
                        onClick={() => setSelectedTag(tag)}
                        key={tag.id}
                        className={`flex flex-row gap-2 items-center hover:bg-[#f1ebf4] p-2 cursor-pointer rounded ${
                          selectedTag && selectedTag.id === tag.id
                            ? 'bg-[#F8ECFF] text-[#8830C0]'
                            : ''
                        }`}>
                        <div
                          style={{ backgroundColor: tag.color || 'black' }}
                          className="w-4 h-4 rounded"></div>
                        <div>{tag.label}</div>
                        <div className="flex-1 min-h-[24px]"></div>
                        <div>({tag.task_count || 0} items)</div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}

            <div className="flex flex-row justify-end items-center gap-6 px-4 mt-2">
              <Button
                uppercase
                variant="subtle"
                color="dark"
                onClick={() => setOpened(false)}>
                CANCEL
              </Button>
              <Button
                disabled={tagOperation === 'selectedTagsOnly' && !selectedTag}
                uppercase
                onClick={() => handleUntagItems()}
                styles={(theme) => ({
                  root: {
                    backgroundColor: '#fa5252 !important',
                  },
                })}
                color="red">
                YES
              </Button>
            </div>
          </div>
          <LoadingOverlay
            visible={apiCallInProgress === 'pending'}
            overlayBlur={2}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
});

export default DatasetItemsUntag;
