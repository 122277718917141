import { createSlice } from "@reduxjs/toolkit";
import { getToolsList } from "../action/tools";

const initialState = {
  isLoading: true,
  tools: null,
  error: null,
};

const toolSlice = createSlice({
  name: "tools",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // /* -------------------------------------------------------------------------- */
    // /*                               GET ALL TOOLS                               */
    // /* -------------------------------------------------------------------------- */
    builder.addCase(getToolsList.pending, (state) => {
      state.isLoading = true;
      state.tools = null;
      state.error = null;
    });
    builder.addCase(getToolsList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.tools = payload;
    });
    builder.addCase(getToolsList.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.tools = null;
      state.error = payload;
    });
    // /* -------------------------------------------------------------------------- */
    // /*                             DELETE MODEL BY ID                             */
    // /* -------------------------------------------------------------------------- */
    // builder.addCase(deleteModelById.pending, (state) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(deleteModelById.fulfilled, (state, { payload }) => {
    //   state.isLoading = false;
    //   state.models = state.models.filter((model) => model.id !== payload);
    // });
    // builder.addCase(deleteModelById.rejected, (state, { payload }) => {
    //   state.isLoading = false;
    //   state.error = payload;
    // });
    // /* -------------------------------------------------------------------------- */
    // /*                               GET MODEL BY ID                              */
    // /* -------------------------------------------------------------------------- */
    // builder.addCase(getModelById.pending, (state, { payload }) => {
    //   state.model.isLoading = true;
    //   state.model.data = null;
    //   state.model.error = null;
    // });
    // builder.addCase(getModelById.fulfilled, (state, { payload }) => {
    //   state.model.isLoading = false;
    //   state.model.data = payload;
    // });
    // builder.addCase(getModelById.rejected, (state, { payload }) => {
    //   state.model.isLoading = false;
    //   state.model.data = null;
    //   state.model.error = payload;
    // });
    // /* -------------------------------------------------------------------------- */
    // /*                              GET PUBLIC MODEL                              */
    // /* -------------------------------------------------------------------------- */
    // builder.addCase(getPublicModelsList.pending, (state, { payload }) => {
    //   state.publicModel.isLoading = true;
    //   state.publicModel.list = null;
    //   state.publicModel.error = null;
    // });
    // builder.addCase(getPublicModelsList.fulfilled, (state, { payload }) => {
    //   state.publicModel.isLoading = false;
    //   state.publicModel.list = payload;
    //   state.publicModel.metadata = payload.metadata;
    // });
    // builder.addCase(getPublicModelsList.rejected, (state, { payload }) => {
    //   state.publicModel.isLoading = false;
    //   state.publicModel.list = null;
    //   state.publicModel.error = payload;
    // });
  },
});

// export const {} = toolSlice.actions;

export default toolSlice.reducer;
