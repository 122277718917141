import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getDataSetData,
  getDataSetList,
} from '../../../../../../action/dataset';
import DatasetItemsArchive from './DatasetItemsArchive';
import DatasetItemsContent from './DatasetItemsContent';
import { DatasetItemsProvider } from './DatasetItemsContext';
import DatasetItemsCopy from './DatasetItemsCopy';
import DatasetItemsDeleteTag from './DatasetItemsDeleteTag';
import DatasetItemsHeader from './DatasetItemsHeader';
import DatasetItemsSplit from './DatasetItemsSplit';
import DatasetItemsTagModal from './DatasetItemsTagModal';
import DatasetItemsUnarchive from './DatasetItemsUnarchive';
import DatasetItemsUntag from './DatasetItemsUntag';

function DatasetItems() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(1);
  const [page, setPage] = useState(1);
  const { datasetId, projectId } = useParams();
  const { isLoading, list, metadata } = useSelector(
    (state) => state.dataset.data
  );
  const [filteredTags, setFilteredTags] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const deleteTagRef = useRef();
  const unTagRef = useRef();
  const copyRef = useRef();
  const archiveRef = useRef();
  const unarchiveRef = useRef();
  const splitRef = useRef();
  const [searchQueryRemoved, setSearchQueryRemoved] = useState(false);

  const fetchDataset = useCallback(() => {
    dispatch(
      getDataSetData({
        datasetId,
        page,
        size: pageSize,
        isFreshCall: true,
        searchQuery,
        filteredTags: Object.keys(filteredTags).join(','),
      })
    );
  }, [dispatch, datasetId, page, pageSize, searchQuery, filteredTags]);

  useEffect(() => {
    fetchDataset();
  }, [page, searchQuery, filteredTags, fetchDataset]);

  useEffect(() => {
    dispatch(getDataSetList({ projectId }));
  }, [dispatch, projectId]);

  const onPageChange = (desiredPage) => {
    if (desiredPage < 1 || desiredPage > metadata.total) {
      return;
    }
    setPage(desiredPage);
  };

  const handleSearch = (query) => {
    setPage(1);
    /**
     * HACK | TODO | for the below issues, till proper fix is implemented.
     * search for something resulting in no data.
     * Now when the search query state is set, the data fetch useEffect will
     * be called.
     * isLoading is false, total will be 0, searchQuery will be blank, the navigation
     * will happen to show the create new item page.
     *
     * That is why, creating this state to maintain a flag the navigation logic should
     * be checked only once.
     */
    setSearchQueryRemoved(true);
    setSearchQuery(query.trim());
  };

  const handleDeleteTag = (tag) => {
    if (deleteTagRef.current) {
      deleteTagRef.current.openDeleteTagModal(tag, metadata.total);
    }
  };

  const handleUntagItems = () => {
    if (unTagRef.current) {
      unTagRef.current.openUntagModal(metadata.total);
    }
  };

  const handleCopyItems = (tag) => {
    if (copyRef.current) {
      copyRef.current.openCopyModal(tag, metadata.total);
    }
  };

  const handleArchiveItems = () => {
    if (archiveRef.current) {
      archiveRef.current.openArchiveModal(metadata.total);
    }
  };

  const handleUnarchiveItems = () => {
    if (unarchiveRef.current) {
      unarchiveRef.current.openUnarchiveModal(metadata.total);
    }
  };

  const handleSplitItems = () => {
    if (splitRef.current) {
      splitRef.current.openSplitModal(metadata.total);
    }
  };

  useEffect(() => {
    if (
      isLoading === false &&
      metadata &&
      metadata?.total === '0' &&
      Object.keys(filteredTags).length === 0 &&
      !searchQuery &&
      !searchQueryRemoved
    ) {
      const path = `/project/${projectId}/dataset/${datasetId}/items/no-item-page`;
      return navigate(path);
    }
  }, [
    isLoading,
    metadata,
    metadata?.total,
    filteredTags,
    searchQuery,
    datasetId,
    projectId,
    navigate,
    searchQueryRemoved,
  ]);

  return (
    <DatasetItemsProvider>
      <div>
        <DatasetItemsHeader
          isLoading={isLoading}
          page={page}
          setPage={setPage}
          totalPage={metadata?.total || 0}
          onPageChange={onPageChange}
          handleSearch={handleSearch}
          handleUntagItems={handleUntagItems}
          handleCopyItems={handleCopyItems}
          handleArchiveItems={handleArchiveItems}
          handleUnarchiveItems={handleUnarchiveItems}
          handleSplitItems={handleSplitItems}
          filteredTags={filteredTags}
          setFilteredTags={setFilteredTags}
        />
        <DatasetItemsContent
          item={list && list.length > 0 ? list[0] : null}
          isLoading={isLoading}
          totalPage={metadata?.total || 0}
          fetchDataset={fetchDataset}
        />
        <DatasetItemsTagModal
          handleDeleteTag={handleDeleteTag}
          taskId={list && list.length > 0 ? list[0].id : null}
          searchQuery={searchQuery}
          filteredTags={filteredTags}
          fetchDataset={fetchDataset}
        />
        <DatasetItemsDeleteTag ref={deleteTagRef} />
        <DatasetItemsUntag
          ref={unTagRef}
          searchQuery={searchQuery}
          filteredTags={filteredTags}
          fetchDataset={fetchDataset}
        />
        <DatasetItemsCopy
          ref={copyRef}
          taskId={list && list.length > 0 ? list[0].id : null}
          searchQuery={searchQuery}
          filteredTags={filteredTags}
        />
        <DatasetItemsArchive
          ref={archiveRef}
          taskId={list && list.length > 0 ? list[0].id : null}
          searchQuery={searchQuery}
          filteredTags={filteredTags}
        />
        <DatasetItemsUnarchive
          ref={unarchiveRef}
          taskId={list && list.length > 0 ? list[0].id : null}
          searchQuery={searchQuery}
          filteredTags={filteredTags}
        />
        <DatasetItemsSplit
          ref={splitRef}
          taskId={list && list.length > 0 ? list[0].id : null}
        />
      </div>
    </DatasetItemsProvider>
  );
}

export default DatasetItems;
