import { createSlice } from '@reduxjs/toolkit';
import {
  createDataSet,
  createTag,
  deleteDatasetById,
  deleteTag,
  getAllTags,
  getAllTools,
  getDataSetById,
  getDataSetConfig,
  getDataSetData,
  getDataSetList,
  updateTag,
  uploadDatasetFile,
} from '../../action/dataset';
import { DATASET_CATEGORIES } from './constants';

const initialState = {
  isLoading: false,
  list: null,
  error: null,
  metadata: {},
  dataset: {
    isLoading: false,
    data: null,
    error: null,
  },
  data: {
    isLoading: false,
    list: [],
    error: null,
  },
  fileUpload: {
    percent: 0,
    error: null,
  },
  config: {
    isLoading: false,
    categories: null,
    error: null,
    defaultCategories: DATASET_CATEGORIES,
  },
  tags: {
    createStatus: 'idle', // idle | pending | fulfilled | rejected,
    getStatus: 'idle', // idle | pending | fulfilled | rejected,
    updateStatus: 'idle', // idle | pending | fulfilled | rejected,
    deleteStatus: 'idle', // idle | pending | fulfilled | rejected,
    errorInCreatingTag: null,
    data: [],
  },
  files: {
    selectedFiles: [],
    acceptedFiles: {},
  },
  tools: {
    getStatus: 'idle', // idle | pending | fulfilled | rejected,
    data: [],
  },
};

const datasetSlice = createSlice({
  name: 'dataset',
  initialState,
  reducers: {
    updateProgress: (state, { payload }) => {
      state.fileUpload.percent = payload;
    },
    updateFiles: (state, { payload }) => {
      if (payload.selectedFiles) {
        state.files.selectedFiles = payload.selectedFiles;
      }
      if (payload.acceptedFiles) {
        state.files.acceptedFiles = payload.acceptedFiles;
      }
    },
    resetFiles: (state) => {
      state.files.selectedFiles = [];
      state.files.acceptedFiles = [];
    }
  },
  extraReducers: (builder) => {
    /* -------------------------------------------------------------------------- */
    /*                              GET DATA SET LIST                             */
    /* -------------------------------------------------------------------------- */
    builder.addCase(getDataSetList.pending, (state, { payload }) => {
      state.isLoading = true;
      state.list = null;
      state.error = null;
    });
    builder.addCase(getDataSetList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.list = payload.data;
      state.metadata = payload.metadata;
    });

    builder.addCase(getDataSetList.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.list = null;
      state.error = payload;
    });
    /* -------------------------------------------------------------------------- */
    /*                            DELETE DATASET BY ID                            */
    /* -------------------------------------------------------------------------- */

    builder.addCase(deleteDatasetById.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(deleteDatasetById.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.list = state.list.filter((dataset) => dataset.id !== payload);
    });

    builder.addCase(deleteDatasetById.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.list = null;
      state.error = payload;
    });
    /* -------------------------------------------------------------------------- */
    /*                             GET DATASET CONFIG                             */
    /* -------------------------------------------------------------------------- */
    builder.addCase(getDataSetConfig.pending, (state, { payload }) => {
      state.config.isLoading = true;
      state.config.categories = null;
      state.config.error = null;
    });
    builder.addCase(getDataSetConfig.fulfilled, (state, { payload }) => {
      state.config.isLoading = false;
      state.config.categories = payload;
    });

    builder.addCase(getDataSetConfig.rejected, (state, { payload }) => {
      state.config.isLoading = false;
      state.config.categories = null;
      state.config.error = payload;
    });
    /* -------------------------------------------------------------------------- */
    /*                                DATASET BY ID                               */
    /* -------------------------------------------------------------------------- */
    builder.addCase(getDataSetById.pending, (state, { payload }) => {
      state.dataset.isLoading = true;
      state.dataset.data = null;
      state.dataset.error = null;
    });
    builder.addCase(getDataSetById.fulfilled, (state, { payload }) => {
      state.dataset.isLoading = false;
      state.dataset.data = payload;
    });

    builder.addCase(getDataSetById.rejected, (state, { payload }) => {
      state.dataset.isLoading = false;
      state.dataset.data = null;
      state.dataset.error = payload;
    });

    /* -------------------------------------------------------------------------- */
    /*                                DATASET BY ID                               */
    /* -------------------------------------------------------------------------- */
    builder.addCase(createDataSet.pending, (state, { payload }) => {
      state.dataset.isLoading = true;
      state.dataset.data = null;
      state.dataset.error = null;
    });
    builder.addCase(createDataSet.fulfilled, (state, { payload }) => {
      state.dataset.isLoading = false;
      state.dataset.data = payload;
    });

    builder.addCase(createDataSet.rejected, (state, { payload }) => {
      state.dataset.isLoading = false;
      state.dataset.data = null;
      state.dataset.error = payload;
    });

    /* -------------------------------------------------------------------------- */
    /*                              GET DATASET DATA                              */
    /* -------------------------------------------------------------------------- */
    builder.addCase(getDataSetData.pending, (state, { payload }) => {
      state.data.isLoading = true;
    });
    builder.addCase(getDataSetData.fulfilled, (state, { payload }) => {
      state.data.list = payload.list;
      state.data.metadata = payload.metadata;
      state.data.isLoading = false;
    });

    builder.addCase(getDataSetData.rejected, (state, { payload }) => {
      state.data.error = payload;
      state.data.isLoading = false;
    });

    /* -------------------------------------------------------------------------- */
    /*                            FILE UPLOAD PERCENT                            */
    /* -------------------------------------------------------------------------- */
    builder.addCase(uploadDatasetFile.pending, (state) => {
      state.fileUpload.percent = 0;
      state.fileUpload.error = null;
    });
    builder.addCase(uploadDatasetFile.fulfilled, (state, { payload }) => {
      state.fileUpload.percent = 100;
    });
    builder.addCase(uploadDatasetFile.rejected, (state, { payload }) => {
      state.fileUpload.error = payload;
    });

    /* -------------------------------------------------------------------------- */
    /*                                    TAGS                                    */
    /* -------------------------------------------------------------------------- */
    builder.addCase(createTag.pending, (state) => {
      state.tags.createStatus = 'pending';
    });
    builder.addCase(createTag.fulfilled, (state) => {
      state.tags.createStatus = 'fulfilled';
    });
    builder.addCase(createTag.rejected, (state) => {
      state.tags.createStatus = 'rejected';
    });
    builder.addCase(getAllTags.pending, (state) => {
      state.tags.getStatus = 'pending';
    });
    builder.addCase(getAllTags.fulfilled, (state, { payload }) => {
      state.tags.getStatus = 'fulfilled';
      state.tags.data = payload;
    });
    builder.addCase(getAllTags.rejected, (state) => {
      state.tags.getStatus = 'rejected';
    });
    builder.addCase(updateTag.pending, (state) => {
      state.tags.updateStatus = 'pending';
    });
    builder.addCase(updateTag.fulfilled, (state, { payload }) => {
      state.tags.updateStatus = 'fulfilled';
    });
    builder.addCase(updateTag.rejected, (state) => {
      state.tags.updateStatus = 'rejected';
    });
    builder.addCase(deleteTag.pending, (state) => {
      state.tags.deleteStatus = 'pending';
    });
    builder.addCase(deleteTag.fulfilled, (state, { payload }) => {
      state.tags.deleteStatus = 'fulfilled';
    });
    builder.addCase(deleteTag.rejected, (state) => {
      state.tags.deleteStatus = 'rejected';
    });

    builder.addCase(getAllTools.pending, (state) => {
      state.tools.getStatus = 'pending';
    });
    builder.addCase(getAllTools.fulfilled, (state, { payload }) => {
      state.tools.getStatus = 'fulfilled';
      state.tools.data = payload;
    });
    builder.addCase(getAllTools.rejected, (state) => {
      state.tools.getStatus = 'rejected';
    });
  },
});

export const { updateProgress, updateFiles, resetFiles } = datasetSlice.actions;

export default datasetSlice.reducer;
