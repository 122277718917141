import React from "react";
import { cn } from "../../utils/cn";

const TextArea = ({
  parentClass = "",
  error,
  name = "",
  label = "",
  inputClass = "",
  row = false,
  labelClass = "",
  ...props
}) => {
  return (
    <div className={`input_container relative pb-5 ${parentClass}`}>
      <label htmlFor={name} className={cn("font-[500]", labelClass)}>
        {label}
      </label>
      <textarea
        ref={props.inref || null}
        type="text"
        id={name}
        placeholder="your text here"
        className={`border w-full py-2 px-5 h-[calc(100%-25px)] rounded-md border-wid border-[#ECECEC] ${inputClass}`}
        {...props}
      />
      {error && (
        <div className="absolute bottom-0 text-xs text-red-500 error">
          {error}
        </div>
      )}
    </div>
  );
};

export default TextArea;
