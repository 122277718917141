import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import authSlice from "../reducer/authSlice";
import billingSlice from "../reducer/billingSlice";
import configSlice from "../reducer/configSlice";
import datasetSlice from "../reducer/datasetSlice/datasetSlice";
import deploymentSlice from "../reducer/deploymentSlice";
import knowledgeBaseSlice from "../reducer/knowledgeBaseSlice";
import modelSlice from "../reducer/modelSlice";
import projectSlice from "../reducer/projectSlice";
import teamSlice from "../reducer/teamSlice";
import toolSlice from "../reducer/toolSlice";
import versionSlice from "../reducer/versionSlice";

const isProduction = process.env.NODE_ENV === "production";

const middleware = isProduction
  ? (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
  : (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(
        createLogger({ collapsed: true })
      );

const store = configureStore({
  reducer: {
    tools: toolSlice,
    knowledgeBase: knowledgeBaseSlice,
    config: configSlice,
    auth: authSlice,
    project: projectSlice,
    team: teamSlice,
    dataset: datasetSlice,
    model: modelSlice,
    version: versionSlice,
    billing: billingSlice,
    deployments: deploymentSlice,
  },
  middleware,
});

export default store;
