import { Input } from '@mantine/core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Markdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getAllTags,
  updateSingleDatasetItem,
} from '../../../../../../action/dataset';
import icons from '../../../../../../Assets/icons/icons';
import Button from '../../../../../../Components/Button/Button';
import Loader from '../../../../../../Components/Loader/Loader';
import TextArea from '../../../../../../Components/TextArea/TextArea';
import CopyToClipboard from './CopyToClipboard';
import {
  DatasetItemsContext,
  DatasetOperationModes,
} from './DatasetItemsContext';

const findTagById = (tagList, tagId) => {
  const tag = tagList.find((t) => t.id === tagId);
  return tag;
};

const SectionTypes = {
  MESSAGES: 'MESSAGES',
  PROMPT: 'PROMPT',
  RESPONSE: 'RESPONSE',
  HISTORY: 'HISTORY',
};

const renderTypes = {
  ARRAY_OF_OBJECT: 'ARRAY_OF_OBJECT',
  ARRAY_OF_STRING: 'ARRAY_OF_STRING',
  ARRAY_OF_ARRAY_OF_STRING: 'ARRAY_OF_ARRAY_OF_STRING',
  STRING: 'STRING',
};

export default function DatasetItemsContent({
  isLoading,
  item,
  totalPage,
  fetchDataset,
}) {
  // -1 => not editing, other value indicates index.
  const [editingContentIndex, setEditingContentIndex] = useState(-1);
  const [editingContentValue, setEditingContentValue] = useState('');
  const [editingContentSectionType, setEditingContentSectionType] =
    useState('');
  const [isSaving, setIsSaving] = useState(false);
  const promptTextAreaRef = useRef();
  const { datasetId } = useParams();
  const { data: tags } = useSelector((state) => state.dataset.tags);
  const dispatch = useDispatch();
  const { setTagModalOpened, setTagModalOperationMode } =
    useContext(DatasetItemsContext);

  useEffect(() => {
    dispatch(getAllTags({ datasetId }));
  }, [datasetId, dispatch]);

  const handleSaveBtnClick = async (sectionType, renderType) => {
    setIsSaving(true);
    const updatedItem = structuredClone(item);
    if (sectionType === SectionTypes.MESSAGES) {
      if (renderType === renderTypes.ARRAY_OF_OBJECT) {
        if (updatedItem.data.messages[editingContentIndex].value) {
          updatedItem.data.messages[editingContentIndex].value =
            editingContentValue;
        } else {
          updatedItem.data.messages[editingContentIndex].content =
            editingContentValue;
        }
      }
    } else if (sectionType === SectionTypes.PROMPT) {
      updatedItem.data.prompt = editingContentValue;
    } else if (sectionType === SectionTypes.RESPONSE) {
      updatedItem.data.response = editingContentValue;
    } else if (sectionType === SectionTypes.HISTORY) {
      updatedItem.data.history[editingContentIndex] = editingContentValue;
    }

    const action = await dispatch(
      updateSingleDatasetItem({
        datasetId: datasetId,
        data: updatedItem,
      })
    );

    if (
      updateSingleDatasetItem.fulfilled.match(action) ||
      updateSingleDatasetItem.rejected.match(action)
    ) {
      setIsSaving(false);
    }

    setEditingContentIndex(-1);
    fetchDataset();
  };

  const handleEditBtnClick = async (
    index,
    content,
    renderType,
    sectionType
  ) => {
    setEditingContentSectionType(sectionType);
    setEditingContentValue(content);
    setEditingContentIndex(index);
  };

  const handleSingleTagOperation = async (item) => {
    setTagModalOperationMode(DatasetOperationModes.SINGLE);
    setTagModalOpened(true);
  };

  const renderArrayOfObjects = (items, sectionType, renderType) => {
    return (
      <div>
        {items.map((item, index) => (
          <div
            key={index}
            className="flex flex-col mt-4 mb-6">
            <div className="flex flex-row items-center gap-2">
              <span>{index}</span>
              <span>&gt;</span>
              <span className="uppercase font-bold">
                {item.from ?? item.role}
              </span>
            </div>

            <div className="pl-10">
              {index !== editingContentIndex && (
                <Markdown>{item.value ?? item.content}</Markdown>
              )}
              <div>
                {renderActionButtons(
                  index,
                  renderTypes.ARRAY_OF_OBJECT,
                  item.value ?? item.content,
                  sectionType
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderActionButtons = (index, renderType, content, sectionType) => {
    return (
      <div>
        <div
          className={`flex flex-row gap-6 items-center ${
            editingContentIndex === index &&
            editingContentSectionType === sectionType
              ? 'hidden'
              : 'inline'
          }`}>
          <CopyToClipboard content={content} />

          <button
            className="disabled:cursor-not-allowed"
            onClick={() =>
              handleEditBtnClick(index, content, renderType, sectionType)
            }
            disabled={editingContentIndex !== -1}>
            <img
              src={icons.editIcon2}
              alt="edit prompt"
              className="w-4 h-4 mt-1"
            />
          </button>

          <button>
            <img
              src={icons.path}
              alt="Prompt Trend"
              className="w-4 h-4"
            />
          </button>
        </div>

        <div
          className={`mt-4 ${
            editingContentIndex === index &&
            editingContentSectionType === sectionType
              ? 'inherit'
              : 'hidden'
          }`}>
          {sectionType === SectionTypes.HISTORY ? (
            <div className="flex flex-col gap-4 mb-6 w-full">
              <div className="flex flex-row gap-4 items-center">
                <div className="font-semibold w-[85px]">Prompt</div>
                <Input
                  aria-label=""
                  classNames={{
                    wrapper: `w-full`,
                  }}
                  value={editingContentValue[0]}
                  onChange={(event) => {
                    return setEditingContentValue((prevValue) => {
                      return [event.target.value, prevValue[1]];
                    });
                  }}
                />
              </div>

              <div className="flex flex-row gap-4 items-center">
                <div className="font-semibold  w-[85px]">Response</div>
                <Input
                  classNames={{
                    wrapper: `w-full`,
                  }}
                  aria-label=""
                  value={editingContentValue[1]}
                  onChange={(event) => {
                    return setEditingContentValue((prevValue) => {
                      return [prevValue[0], event.target.value];
                    });
                  }}
                />
              </div>
            </div>
          ) : (
            <TextArea
              ref={promptTextAreaRef}
              rows={editingContentValue?.length > 100 ? 10 : 2}
              value={editingContentValue}
              onChange={(event) => setEditingContentValue(event.target.value)}
            />
          )}

          <div className="flex flex-row items-start gap-4">
            <Button
              disabled={isSaving}
              intent="subtle"
              onClick={() => setEditingContentIndex(-1)}>
              CANCEL
            </Button>
            <Button
              onClick={() => handleSaveBtnClick(sectionType, renderType)}
              isLoading={isSaving}
              disabled={isSaving}>
              SAVE
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const renderArrayOfString = (items) => {
    <div></div>;
  };

  const determineRenderType = (content) => {
    if (Array.isArray(content) && content.length > 0) {
      if (typeof content[0] === 'string') {
        return renderTypes.ARRAY_OF_STRING;
      } else if (Array.isArray(content[0])) {
        return renderTypes.ARRAY_OF_ARRAY_OF_STRING;
      } else {
        return renderTypes.ARRAY_OF_OBJECT;
      }
    }
  };

  const renderHistory = (history) => {
    const renderType = determineRenderType(history);
    let mainContent;
    if (renderType === renderTypes.ARRAY_OF_ARRAY_OF_STRING) {
      mainContent = (
        <div>
          {history.map((item, index) => (
            <div
              key={index}
              className="flex flex-col mt-4 w-full mb-6">
              <div className="flex flex-row gap-2">
                <span className="mt-[1px]">{index}</span>
                <span>&gt;</span>
                <div className="flex-1">
                  {index !== editingContentIndex && (
                    <>
                      <div className="flex flex-row gap-4">
                        <div className="font-semibold w-[72px]">Prompt</div>
                        <Markdown>{item[0]}</Markdown>
                      </div>
                      <div className="flex flex-row gap-4">
                        <div className="font-semibold w-[72px]">Response</div>
                        <Markdown>{item[1]}</Markdown>
                      </div>
                    </>
                  )}
                  <div>
                    {renderActionButtons(
                      index,
                      renderTypes.ARRAY_OF_ARRAY_OF_STRING,
                      item,
                      SectionTypes.HISTORY
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    } else if (renderType === renderTypes.ARRAY_OF_OBJECT) {
      mainContent = renderArrayOfObjects(history);
    }
    return (
      <div className="my-5">
        <h2
          style={{ color: '#289915' }}
          className={`font-bold tracking-wide`}>
          HISTORY
        </h2>

        {mainContent}
      </div>
    );
  };

  const renderMessages = (messages) => {
    let mainContent;
    const renderType = determineRenderType(messages);
    if (renderType === renderTypes.ARRAY_OF_STRING) {
    } else if (renderType === renderTypes.ARRAY_OF_OBJECT) {
      mainContent = renderArrayOfObjects(
        messages,
        SectionTypes.MESSAGES,
        renderType
      );
    }

    return (
      <div className="my-5">
        <h2
          style={{ color: '#3B4A50' }}
          className={`font-bold tracking-wide`}>
          MESSAGES
        </h2>
        {mainContent}
      </div>
    );
  };

  if (totalPage == 0 && isLoading === false) {
    return (
      <div className="rounded-md border border-gray-300 py-4 px-4 max-h-[300px] mt-8 grid place-content-center">
        <p className="text-2xl flex-1 text-center">No Item Found!</p>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="rounded-md border border-gray-300 py-4 px-4 max-h-[300px] mt-8 grid place-content-center">
        <Loader />
      </div>
    );
  }

  return (
    <div className="my-8 overflow-y-auto">
      <div className="rounded-md border border-gray-300 py-4 px-4 min-h-[300px]">
        {/* Tag Row */}
        <div className="flex flex-row flex-wrap items-center gap-4 relative pr-[230px]">
          {item?.tags &&
            item?.tags.length > 0 &&
            item?.tags.map((tag) => {
              const tagDetails = findTagById(tags, tag);
              return (
                <div
                  key={tag}
                  className={`py-1 px-3 font-medium min-w-fit rounded-2xl  text-[13px]`}
                  style={{ backgroundColor: tagDetails?.color || 'lightgrey' }}>
                  {tagDetails?.label || 'Non Existing tag'}
                </div>
              );
            })}
          <div className="flex-1"></div>

          <div className="flex flex-row items-center gap-4 rounded-lg px-4 py-3 border border-gray-300 absolute -top-2 right-0">
            <button onClick={() => handleSingleTagOperation(item)}>
              <img
                src={icons.tag}
                alt="add or remove columns"
                className="w-4 mx-2"
              />
            </button>
            <button>
              <img
                src={icons.archive}
                alt="archive"
                className="w-5 mx-2"
              />
            </button>
            {/* <button>
              <img
                src={icons.addCircle}
                alt="add column"
                className="w-5 mx-2"
              />
            </button>
            <button>
              <img
                src={icons.substractCircle}
                alt="remove column"
                className="w-5 mx-2"
              />
            </button> */}
          </div>
        </div>

        {/* Prompt */}
        {item?.data?.prompt && (
          <div className="flex flex-col gap-4 mt-5">
            <h2 className="text-[#CA4E27] font-bold text-xs tracking-wide">
              PROMPT
            </h2>
            <p
              className={`${
                editingContentIndex === 0 &&
                editingContentSectionType === SectionTypes.PROMPT
                  ? 'hidden'
                  : 'block'
              }`}>
              <Markdown>{item && item.data.prompt}</Markdown>
            </p>
            <div>
              {renderActionButtons(
                0,
                renderTypes.STRING,
                item.data.prompt,
                SectionTypes.PROMPT
              )}
            </div>
          </div>
        )}

        {/* Response */}
        {item?.data?.response && (
          <div className="flex flex-col gap-4 pt-4 mt-6">
            <h2 className="text-[#FF44CB] font-bold text-xs tracking-wide">
              RESPONSE
            </h2>
            <p
              className={`${
                editingContentIndex === 0 &&
                editingContentSectionType === SectionTypes.RESPONSE
                  ? 'hidden'
                  : 'block'
              }`}>
              <Markdown>{item && item.data.response}</Markdown>
            </p>
            <div>
              {renderActionButtons(
                0,
                renderTypes.STRING,
                item.data.response,
                SectionTypes.RESPONSE
              )}
            </div>
          </div>
        )}

        {/* History */}
        {item?.data.history && renderHistory(item.data.history)}

        {/* Messages */}
        {item?.data?.messages && renderMessages(item?.data?.messages)}
      </div>
    </div>
  );
}
