import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../service/axios";

export const getToolsList = createAsyncThunk(
  "tools/tools_list",
  async (projectId, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/tools/v1/tools/project/${projectId}`);
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
