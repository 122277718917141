import { lazy } from "react";

const ProtectedRoute = lazy(() =>
  import("../../Components/ProtectedRoute/ProtectedRoute")
);
const DatasetStart = lazy(() =>
  import("../../Compositions/Dataset/DatasetStart/DatasetStart")
);
const DatasetSteps = lazy(() =>
  import("../../Compositions/Dataset/DatasetSteps/DatasetSteps")
);
const Allset = lazy(() => import("./Auth/Allset/Allset"));
const Login = lazy(() => import("./Auth/Login/Login"));
const ResetPasswordTrigger = lazy(() =>
  import("./Auth/ResetPassword/ResetPasswordTrigger")
);
const ResetPassword = lazy(() => import("./Auth/ResetPassword/ResetPassword"));
const Signup = lazy(() => import("./Auth/Signup/Signup"));
const VerifyOtp = lazy(() => import("./Auth/VerifyOtp/VerifyOtp"));
const Home = lazy(() => import("./Home/Home"));
const AllProjects = lazy(() => import("./Home/Projects/AllProjects"));
const CreateProject = lazy(() => import("./Home/Projects/CreateProject"));
const OwnedProjects = lazy(() => import("./Home/Projects/OwnedProjects"));
const SharedProjects = lazy(() => import("./Home/Projects/SharedProjects"));
const ApiKey = lazy(() => import("./Project/ApiKey/ApiKey"));
const Dashboard = lazy(() => import("./Project/Dashboard/Dashboard"));
const DataSetCreate = lazy(() =>
  import("./Project/DataSet/DataSetCreate/DataSetCreate")
);
const DatasetList = lazy(() =>
  import("./Project/DataSet/DatasetList/DatasetList")
);
const Dataset = lazy(() => import("./Project/DataSet/DataSet"));
const Models = lazy(() => import("./Project/Models/Models"));
const ModelList = lazy(() => import("./Project/Models/ModelList/ModelList"));
const CreateModel = lazy(() =>
  import("./Project/Models/CreateModel/CreateModel")
);
const Model = lazy(() => import("./Project/Models/Model/Model"));
const Version = lazy(() => import("./Project/Models/Model/Version/Version"));
const ModelSetting = lazy(() =>
  import("./Project/Models/Model/ModelSetting/ModelSetting")
);
const ModelAPI = lazy(() => import("./Project/Models/Model/ModelAPI/ModelAPI"));
const Demo = lazy(() => import("./Project/Models/Model/Demo/Demo"));
const Project = lazy(() => import("./Project/Project"));
const ProjectSettings = lazy(() =>
  import("./Project/ProjectSettings/ProjectSettings")
);
const Profile = lazy(() => import("./Settings/Profile/Profile"));
const Settings = lazy(() => import("./Settings/Settings"));
const Support = lazy(() => import("./Settings/Support/Support"));
const CreateTeam = lazy(() => import("./Settings/Teams/CreateTeam/CreateTeam"));
const UpdateTeam = lazy(() => import("./Settings/Teams/UpdateTeam/UpdateTeam"));
const Billing = lazy(() => import("./Settings/Teams/Team/Billing/Billing"));
const Members = lazy(() => import("./Settings/Teams/Team/Members/Members"));
const Team = lazy(() => import("./Settings/Teams/Team/Team"));
const TeamSettings = lazy(() =>
  import("./Settings/Teams/Team/TeamSettings/TeamSettings")
);
const TeamList = lazy(() => import("./Settings/Teams/TeamList/TeamList"));
const Teams = lazy(() => import("./Settings/Teams/Teams"));
const BillingSideBar = lazy(() =>
  import("./Settings/Teams/Team/Billing/Billing")
);
const ChatPublic = lazy(() => import("./ChatPublic/ChatPublic"));
const UpdateProject = lazy(() =>
  import("./Home/Projects/UpdateProject/UpdateProject")
);
const DatasetUploadFile = lazy(() =>
  import("./Project/DataSet/DatasetUploadFile/DatasetUploadFile")
);
const DatasetImportPip = lazy(() =>
  import("./Project/DataSet/DatasetImportPip/DatasetImportPip")
);

const DatasetFileMapping = lazy(() =>
  import("./Project/DataSet/DatasetFileMapping/DatasetFileMapping")
);
const DatasetConnectToCloud = lazy(() =>
  import("./Project/DataSet/DatasetConnectToCloud/DatasetConnectToCloud")
);
const DatasetProgress = lazy(() =>
  import("./Project/DataSet/DatasetProgress/DatasetProgress")
);
const VersionCreateType = lazy(() =>
  import("./Project/Models/Model/Version/VersionCreateType/VersionCreateType")
);
const CreateVersion = lazy(() =>
  import("./Project/Models/Model/Version/CreateVersion/CreateVersion")
);
const VersionList = lazy(() =>
  import("./Project/Models/Model/Version/VersionList/VersionList")
);
const VersionOverview = lazy(() =>
  import(
    "./Project/Models/Model/Version/Version/VersionOverview/VersionOverview"
  )
);
const VersionDemo = lazy(() =>
  import("./Project/Models/Model/Version/Version/VersionDemo/VersionDemo")
);

const VersionApi = lazy(() =>
  import("./Project/Models/Model/Version/Version/VersionApi/VersionApi")
);
const VersionSetting = lazy(() =>
  import("./Project/Models/Model/Version/Version/VersionSetting/VersionSetting")
);
const Invitation = lazy(() => import("./Invitation/Invitation"));

const LS = lazy(() => import("./Labelling/Labelling"));
const Deployments = lazy(() => import("./Project/Deployments/Deployments"));
const DeploymentsList = lazy(() =>
  import("./Project/Deployments/DeploymentsList/DeploymentsList")
);
const DeploymentCreate = lazy(() =>
  import("./Project/Deployments/DeploymentCreate/DeploymentCreate")
);
const KnowledgeBase = lazy(() =>
  import("./Project/knowledgeBase/knowledgeBase")
);
const KnowledgeBaseList = lazy(() =>
  import("./Project/knowledgeBase/List/List")
);
const KnowledgeBaseCreate = lazy(() =>
  import("./Project/knowledgeBase/Create/Create")
);
const KnowledgeBaseTab = lazy(() => import("./Project/knowledgeBase/Tab/Tab"));
const KnowledgeBaseFiles = lazy(() =>
  import("./Project/knowledgeBase/Tab/Files/Files")
);
const KnowledgeBaseFilesUpload = lazy(() =>
  import("./Project/knowledgeBase/Tab/Files/Upload/Upload")
);
const KnowledgeBaseSettings = lazy(() =>
  import("./Project/knowledgeBase/Tab/Settings/Settings")
);
const KnowUser = lazy(() => import("./KnowUser/KnowUser"));
const Tools = lazy(() => import("./Project/Tools/Tools"));
const ToolsList = lazy(() => import("./Project/Tools/List/List"));
const ToolsCreate = lazy(() => import("./Project/Tools/Create/Create"));

export {
  AllProjects,
  Allset,
  ApiKey,
  Billing,
  BillingSideBar,
  ChatPublic,
  CreateModel,
  CreateProject,
  CreateTeam,
  CreateVersion,
  Dashboard,
  Dataset,
  DatasetConnectToCloud,
  DataSetCreate,
  DatasetFileMapping,
  DatasetImportPip,
  DatasetList,
  DatasetProgress,
  DatasetStart,
  DatasetSteps,
  DatasetUploadFile,
  Demo,
  DeploymentCreate,
  Deployments,
  DeploymentsList,
  Home,
  Invitation,
  KnowledgeBase,
  KnowledgeBaseCreate,
  KnowledgeBaseFiles,
  KnowledgeBaseFilesUpload,
  KnowledgeBaseList,
  KnowledgeBaseSettings,
  KnowledgeBaseTab,
  KnowUser,
  Login,
  LS,
  Members,
  Model,
  ModelAPI,
  ModelList,
  Models,
  ModelSetting,
  OwnedProjects,
  Profile,
  Project,
  ProjectSettings,
  ProtectedRoute,
  ResetPassword,
  ResetPasswordTrigger,
  Settings,
  SharedProjects,
  Signup,
  Support,
  Team,
  TeamList,
  Teams,
  TeamSettings,
  Tools,
  ToolsCreate,
  ToolsList,
  UpdateProject,
  UpdateTeam,
  VerifyOtp,
  Version,
  VersionApi,
  VersionCreateType,
  VersionDemo,
  VersionList,
  VersionOverview,
  VersionSetting,
};
